import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

//locals
import { productScoresReducer } from "./product-scores";
import { ingredientsReducer } from "./ingredients";

// root reducer

const rootReducer = combineReducers({
  ingredients: ingredientsReducer,
  products: productScoresReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

// setup listeners
setupListeners(store.dispatch);

export default store;
