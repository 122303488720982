import { createSlice } from "@reduxjs/toolkit";

//locals
import { initialIngredientsState } from "./state";
import { fetchIngredients } from "./thunks";

//fetch
function buildFetchIngredients(builder) {
  builder.addCase(fetchIngredients.pending, (state, action) => {
    state.error = undefined;
    state.isLoading = true;
    return state;
  });
  builder.addCase(fetchIngredients.fulfilled, (state, action) => {
    state.error = undefined;
    state.isLoading = false;
    state.ingredients = action.payload;
    return state;
  });
  builder.addCase(fetchIngredients.rejected, (state, action) => {
    state.error = action.payload;
    state.isLoading = false;
    return state;
  });
}

//slice
export const ingredientsSlice = createSlice({
  name: "ingredients",
  initialState: initialIngredientsState,
  reducers: {},
  extraReducers: (builder) => {
    buildFetchIngredients(builder);
  },
});

//reducer
export const ingredientsReducer = ingredientsSlice.reducer;
