import { createAsyncThunk } from "@reduxjs/toolkit";

import { ingredientService } from "../../services/ingredients/ingredients";

export const fetchIngredients = createAsyncThunk(
  "ingredients/fetchIngredients",
  async (thunkAPI) => {
    const response = await ingredientService.fetchIngredientsData();
    //console.log("thunk", response);
    return response;
  }
  
);
