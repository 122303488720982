import { createSlice } from "@reduxjs/toolkit";

//locals
import { initialProductScoresState } from "./state";
import { fetchProductScores } from "./thunks";

//fetch
function buildFetchProductScores(builder) {
  builder.addCase(fetchProductScores.pending, (state, action) => {
    state.error = undefined;
    state.isLoading = true;
    return state;
  });
  builder.addCase(fetchProductScores.fulfilled, (state, action) => {
    state.error = undefined;
    state.isLoading = false;
    state.products = action.payload;
    return state;
  });
  builder.addCase(fetchProductScores.rejected, (state, action) => {
    state.error = action.payload;
    state.isLoading = false;
    return state;
  });
}

//slice
export const productScoresSlice = createSlice({
  name: "products",
  initialState: initialProductScoresState,
  reducers: {},
  extraReducers: (builder) => {
    buildFetchProductScores(builder);
  },
});

//reducer
export const productScoresReducer = productScoresSlice.reducer;
