export default function SliderContainer(props) {
  if (props.texts === 2) {
    return (
      <div className="slider-container">
        {props.children}
        <div className="slider-container-content">
          <div style={{width: '36%'}} className="slider-container-text-left">
            {props.sliderTextLeft}
          </div>
          <div style={{width: '36%'}} className="slider-container-text-right">
            {props.sliderTextRight}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="slider-container">
        {props.children}
        <div className="slider-container-content">
          <div className="slider-container-text-left">
            {props.sliderTextLeft}
          </div>
          <div className="slider-container-text-center">
            {props.sliderTextCenter}
          </div>
          <div className="slider-container-text-right">
            {props.sliderTextRight}
          </div>
        </div>
      </div>
    );
  }
}
