import { createAsyncThunk } from "@reduxjs/toolkit";

import { productScoresService } from "../../services/product-scores/product-scores";

// Define the async thunk for fetching the product list
export const fetchProductScores = createAsyncThunk(
  "products_scores/fetchProductScores",
  async (thunkAPI) => {
    try {
      const resProductScores =
        await productScoresService.fetchProductScoresData();
      return resProductScores;
    } catch (error) {
      console.log("thunk in product scores", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
