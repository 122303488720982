// import { useState } from 'react';

export default function ColorCircle(props) {

return (
  <div className="container__color-circle">
    <div
      className={
        props.color === props.selectedColor
          ? 'color-circle color-circle_selected'
          : 'color-circle'
      }
      style={{ backgroundImage: props.color }}
      onClick={() => props.handleSelectResponse(props.color, props.score)}
    >
    </div>
    {props.text ? <h4 className="color-circle_text">{props.text}</h4>: ""}
  </div>
  );
}
